import React, { FC } from 'react';
import { Link, useHistory, NavLink } from 'react-router-dom'
import { auth } from '../../services';
import StudySelector from './study-selector';
import { useAppSelector } from '../../store/hooks';
import { selectAvailableViews } from '../../store/slices/views';
import { selectSlice } from '../../store/slices/studies';

export interface NavbarProps {
  showControls?: boolean;
  showMenu?: boolean;
}

// Muestra los filtros globales y la navegación
const Navbar: FC<NavbarProps> = ({
  showControls,
  showMenu,
}) => {

  const history = useHistory();
  const availableViews = useAppSelector(selectAvailableViews)
  const studies = useAppSelector(selectSlice);
  const selectedId = studies.selected?._id;
  const enabledLinks = availableViews.filter(view => view.viewOptions.showOnNavbar && selectedId === view.studyId)

  function logout () {
    auth.logout();
    history.replace('/auth/login');
  }

  return (
    <nav className="navbar">
      <Link to="/start" style={{ alignSelf: 'center' }}>
        <img src={process.env.REACT_APP_URL_BRAND_LOGO} loading="lazy" alt="" className="navbar-brand" />
      </Link>
      <div className="navbar-container">
        {showControls && (
          <div className="navbar-controls">
            <StudySelector />
            {/* <select id="field-2" name="field-2" data-name="Field 2" className="navbar-select w-select">
              <option value="">Cuestionario...</option>
              <option value="Agentes">Agentes</option>
              <option value="Empresas">Empresas</option>
            </select> */}
          </div>
        )}
        {showMenu && (
          <div className="navbar-menu">
            {enabledLinks.map(({ viewName, viewOptions }) => (
              <NavLink
                key={viewName as React.Key}
                to={`/${viewName}`}
                className="navbar-link"
                activeClassName="active"
              >
                {viewOptions.pageTitle}
              </NavLink>
            ))}
          </div>
        )}
      </div>
      {/* <a className="navbar-user-badge w-inline-block" onClick={logout}>
        <span>U</span>
      </a> */}
      <a className="navbar-link" style={{ cursor: 'pointer' }} onClick={logout}>
        Cerrar sesión
      </a>
    </nav>
  );
}

export default Navbar;
export {
  Navbar,
}