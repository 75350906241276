import React, { FC } from 'react';
import {
  LabelList,
  Funnel,
  FunnelChart as ReFunnelChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { LightChart, MetricType } from '../../../services/analytics';
import { toNumber, toPercentage } from '../../../utils';

type DisplayField = keyof Omit<LightChart, 'value'> | MetricType;
type DisplayType = 'absolute' | 'percentage';
type FormatMaker = (displayField: DisplayField, displayType: DisplayType) => (value: number, name: any, props: any) => any[];

export interface PerformanceFunnelData {
  result?: number;
  rate: string;
  name: string;
  fill: string;
}

export interface FunnelChartProps {
  data: PerformanceFunnelData[];
  displayField: DisplayField;
  displayType: DisplayType;
}

const _f: any = {
  absolute: toNumber,
  percentage: toPercentage,
}

const makeFormatTooltip: FormatMaker = (displayField, displayType) => (value, name, props) => {
  return [`${_f[displayType](value)}`, name]
}

const FunnelChart: FC<FunnelChartProps> = ({
  data,
  displayField,
  displayType,
}) => {

  return (
    <ResponsiveContainer
      width="64%"
      height={320}
    >
      <ReFunnelChart width={400} height={400}>
        <Tooltip
          cursor={{ stroke: "#DFE2E3" }}
          offset={40}
          separator=": "
          labelFormatter={() => ``}
          formatter={makeFormatTooltip(displayField, displayType)}
        />
        <Funnel
          dataKey="result"
          data={data}
        >
          <LabelList position="right" fill="#000" stroke="none" dataKey="name" fontSize={10} />
          <LabelList position="insideTop" fill="#000" stroke="none" dataKey="rate" fontSize={10} />
        </Funnel>
      </ReFunnelChart>
    </ResponsiveContainer>
  )
}

export {
  FunnelChart,
}