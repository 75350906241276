import React, { FC, InputHTMLAttributes } from 'react';

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  fieldName: string;
  inputProps?: any;
};

const defaultInputProps: InputHTMLAttributes<HTMLInputElement> = {
  type: 'text',
  maxLength: 256,
};

const TextInput: FC<TextInputProps> = ({
  label,
  fieldName,
  ...inputProps
}) => {
  return (
    <div className="form-control">
      <label htmlFor={fieldName} className="input-label">{label}</label>
      <input name={fieldName} className="text-input w-input" {...defaultInputProps} {...inputProps} />
    </div>
  );
}

export default TextInput;
export {
  TextInput,
};