import { useEffect, useState } from 'react'
import BaseTable from '../../../common/widgets/table.w'
import { GridCellParams, GridColDef } from '@mui/x-data-grid'
import { ReportConfig } from '../../../services/views'
import { SupportedLanguages } from '../../../services/filters'
import useReportDataList, { UseDataListConfig } from '../../../common/hooks/use-data-list'
import { Tooltip } from '@material-ui/core'

export interface ScaleFormatDef {
  max: number,
  className: string,
}

export type DataListProps = ReportConfig<
  UseDataListConfig,
  {
    limit?: number;
    hidePagination: boolean;
  }
>

const baseColProps: Partial<GridColDef> = {
  headerAlign: 'left',
  align: 'left',
  type: 'string',
  sortable: true,
  renderCell: ({ value }: GridCellParams) => (
    <Tooltip title={value || ''}>
      <span>{value}</span>
    </Tooltip>
  ),
}

const DataListTable = ({
  title = { default: '', es: '' },
  query,
  options: {
    limit,
    hidePagination
  },
}: DataListProps) => {
  const [loadReport, loading, error, reportDataList] = useReportDataList(query);
  const [tableCols, setTableCols] = useState<GridColDef[]>([]);
  const [data, setData] = useState<Array<any>>([]);
  const preferredLang = SupportedLanguages.es
  const cols: GridColDef[] = []
  const hide = hidePagination ? hidePagination : false;

  useEffect(() => {
    loadReport();
  }, [loadReport]);

  useEffect(() => {
    if (!loading && !error && reportDataList) {
      const firstDetail =  reportDataList && reportDataList[0];
      if (firstDetail) {
        Object.keys(firstDetail).forEach(key => {
          const attributes = {
            ...baseColProps,
            field: key,
            headerName: key
          }
          if(key !== '_id' && key !=='createdAt'){
            if (firstDetail?.Comentario) Object.assign(attributes, {width: key === 'Comentario' ? 480 : 160})
            else  Object.assign(attributes, {flex: 0.5})
            cols.push(attributes)
          }
        });
      }
      setTableCols(cols);
      setData(reportDataList)
    }
  }, [loading, error, reportDataList]);

  return (
    <div className="span-two-cols">
      <BaseTable
        rowIdKey="_id"
        rows={data}
        columns={tableCols}
        loading={loading}
        onReload={loadReport}
        rowHeight={32}
        autoHeight
        tableTitle={title[preferredLang]}
        downloadFileName={title[preferredLang]}
        pageSize={limit}
        hideFooter={hide}
        localeText={{
          toolbarFilters: '',
          toolbarExport: '',
          toolbarColumns: 'Columnas',
          noRowsLabel: 'No se encontraron registros.',
          toolbarExportCSV: 'Descargar CSV',
          toolbarExportLabel: 'Exportar',
          toolbarFiltersTooltipShow: 'Mostrar filtros',
          toolbarFiltersTooltipHide: 'Ocultar filtros',
          MuiTablePagination: {
            labelRowsPerPage: 'Registros por página:',
            labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} de ${count}`,
          }
        }}
      />
    </div>
  )
}

export {
  DataListTable
}