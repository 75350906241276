import React from 'react'
import { InfoMessageConfig } from '../../services/views'
import './view-banner.css'

const ViewBanner = ({
  title,
  description,
  buttons
}: InfoMessageConfig) => (
  <div className="banner-container">
    <div>
      <p className="banner-title">{title.es}</p>
      <p className="banner-text">{description.es}</p>
    </div>
    <div>
      {buttons?.map(b => (
        <a href={b.link} target='_blank' className="banner-button">{b.label.es}</a>
      ))}
    </div>
  </div>
)

export {
  ViewBanner
}