import { Import, Imports, constants, ServerResponse } from '../utils';
import { storage } from '.';


async function getImports (studyId?: string, conditions?: any): Promise<Imports> {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let URL = `${constants.API_URL}/import`;
  let fullConditions = {};
  if (conditions) {
    fullConditions = conditions;
  }
  if (studyId) {
    fullConditions = { ...fullConditions, studyId }
  }
  if (conditions || studyId) {
    URL += `?conditions=${JSON.stringify(fullConditions)}`
  }
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ServerResponse<Imports> = await fetchResponse.json();
  return response.data;
}

async function importByFile (studyId: string, datamapId: string, files: FileList): Promise<Import> {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const formData = new FormData();
  formData.append('studyId', studyId);
  formData.append('datamapId', datamapId);
  formData.append('file', files?.[0]);
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`
    },
    body: formData,
  };
  let URL = `${constants.API_URL}/import/import-file`;
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: Import = await fetchResponse.json();
  return response;
}

export {
  importByFile,
  getImports,
}