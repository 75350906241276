import { configureStore } from '@reduxjs/toolkit';
import filtersReducer from './slices/filters';
import studiesReducer from './slices/studies';
import viewsReducer from './slices/views';

export const store = configureStore({
  reducer: {
    filters: filtersReducer,
    studies: studiesReducer,
    views: viewsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: true
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;