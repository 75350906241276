import { DateRange } from '../../utils';
import { AnalyticsDateRange, Filter } from '../analytics'
import { AdditionalField, PaginationOptions } from '../comments';
import { LocalizedText, TimeUnit } from '../filters';

export enum CalcType {
  AVG = 'AVG',
  NPS = 'NPS',
  ANSWERS = 'ANSWERS',
  QUESTION = 'QUESTION'
}

interface AvgCalcOptions {
  precision: number,
  scaleFactor: number,
  invitations?: boolean
}

interface NpsCalcOptions {
  precision?: number,
  scaleFactor?: number,
  invitations?: boolean
}

export type CalcParams<T = CalcType> = {
  type: CalcType,
  options?:
    T extends CalcType.AVG ? AvgCalcOptions
    : T extends CalcType.NPS ? NpsCalcOptions
    : never;
}

interface VariableCalc {
  name: string,
  calc: CalcParams
}

interface VarGroup {
  distincId: string,
  groupCalc?: CalcParams,
  variables: Array<VariableCalc>
}

export interface SegmentedReportQuery {
  studyId: string,
  dateRange: AnalyticsDateRange,
  breakDownField: string,
  groups: Array<VarGroup>,
  filters?: Filter[]
}

export interface CatalogItem {
  distinctId: string,
  displayValue: LocalizedText
}

interface BasicResult {
  details?: any;
  n: number,
  result: number,
  percentage?: number,
}

export interface VarSegmentedResult {
  distinctId: string,
  n: number,
  display: LocalizedText,
  breakdown: Record<string, BasicResult>
}

interface VarGroupResult {
  distinctId: string;
  detail: Array<VarSegmentedResult>
}

export interface SegmentedReport {
  breakDownDetail: Array<CatalogItem>,
  groups: Array<VarGroupResult>,
}

export interface ReportResponse<R> {
  message: string,
  report: R,
}

export type SegmentedReportResponse = ReportResponse<SegmentedReport>

interface KpiSummaryDetail {
  n: number,
  percentage: number,
  distinctId: string,
  display: {
    value: string,
    display: LocalizedText
  }
}

interface KpiSummary {
  question?: LocalizedText;
  n: number,
  result: number,
  detail: Array<KpiSummaryDetail>
}

export interface KpiSummaryReport {
  report: KpiSummary,
  comparision: KpiSummary,
  comparisonDateRange: {
    start: string,
    end: string,
  }
}

export interface KpiSummaryReportQuery {
  studyId: string,
  dateRange: AnalyticsDateRange,
  field: string,
  calc: CalcParams,
  compareDateRange?: Omit<AnalyticsDateRange, 'field'>
  filters?: Filter[]
}

export interface KpiTrendReportQuery {
  resultId?: string;
  studyId: string,
  dateRange: AnalyticsDateRange,
  field: string,
  calc: CalcParams,
  timeUnit: TimeUnit,
  filters?: Filter[]
}

interface KpiTrendPeriod {
  n: number,
  result: number,
  dateRange: Omit<AnalyticsDateRange, 'field'>,
  detail: Array<KpiSummaryDetail>,
  invitations?: number
}

export interface KpiTrendReport {
  n: number,
  periods: Array<KpiTrendPeriod>
}

// Multimetric
export interface MultimetricTrendReportQuery {
  studyId: string,
  dateRange: AnalyticsDateRange,
  breakDownField: string,
  timeUnit: TimeUnit,
  variables: Array<{
    name: string,
    calc: CalcParams,
  }>
  filters?: Filter[]
}

interface MultimetricTrendDetail {
  distinctId: string,
  display: LocalizedText,
  periods: Array<{
    dateRange: {
      start: string,
      end: string,
    },
    timeUnit: string | number,
    vars: Array<{
      n: number,
      result: number,
      field: string
    }>
  }>
}

export interface MultimetricTrendReport {
  n: number,
  varDetails: Array<{
    distinctId: string,
    display: LocalizedText,
  }>,
  breakdown: Record<string, MultimetricTrendDetail>
}

// VarGroup Trend
export interface MultivarTrendReportQuery {
  studyId: string,
  dateRange: AnalyticsDateRange,
  timeUnit: TimeUnit,
  groups: Array<VarGroup>,
  filters?: Filter[]
}

export interface MultivarTrendReport {
  groups: Array<VarGroupResult>
}

// NPS Breakdown
export interface NpsBreakdownReportQuery {
  studyId: string,
  dateRange: AnalyticsDateRange,
  filters?: Filter[],
  breakDownField: string,
  npsField: string,
}

interface NpsDetail {
  n: number,
  percentage: number,
  globalPercentage: number,
}

export type NpsBreakdownReport = Array<{
  display: LocalizedText,
  nps: number,
  promoter: NpsDetail,
  neutral: NpsDetail,
  detractor: NpsDetail,
  total: NpsDetail,
}>

//CSAT Attributes
export interface CSATAttributesReportQuery {
  studyId: string,
  dateRange: AnalyticsDateRange,
  variable: {
    name: string,
    calc: CalcParams,
  },
  attributes: string[],
  filters?: Filter[]
}
export interface CSATAttributesReport {
  n: number,
  variableDetails:{
    distinctId: string,
    display: LocalizedText,
  },
  dataReport: Array<DataReport>,
}
export interface DataReport {
  id: string,
  display: LocalizedText,
  n: number,
  negative: number,
  positive: number,
  percentage: number,
  negative_percentage: number,
  positive_percentage: number,
  result: number,
  result_percentage: number
}

export interface GetDataListOptions {
  studyId: string;
  range: DateRange;
  dateRangeField: string;
  valueMainField: string;
  mainField: string; 
  display?:string;
  additionalFields?: AdditionalField[];
  filters?: Filter[];
  pagination: PaginationOptions
}

export interface GetDataListRequest {
  studyId: string;
  dateRange: AnalyticsDateRange;
  valueMainField: string;
  mainField: string; 
  additionalFields?: AdditionalField[];
  filters?: Filter[];
  display?:string;
}
