import { Moment } from 'moment';
export interface MomentRange {
  start?: Moment;
  end?: Moment;
}

export interface Study {
  _id: string;
  name: string;
  importDatamapId: string;
}

export interface Import {
  _id: string;
  id: string;
  createdAt: string;
  completeRecords: number;
  datamapId: string;
  discardedRecords: number;
  fileId: string;
  fileObj: {
    _id: string;
    originalname: string;
  }
  status: string;
  studyId: string;
  typeInput: string;
  updatedAt: string;
}

export interface Export {
  _id: string;
  createdAt: string;
  totalInteractions: number; // Total respuestas procesados
  discardInteractions: number; // Total respuestas descartados
  fileId: string;
  status: string;
  surveyId: string;
}

export interface Survey {
  _id: string;
  name: string;
}

export enum AgentPhase {
  INICIO = 'Inicio',
  DURANTE = 'Durante',
  ABANDONADOR = 'Abandonador',
  POST = 'Post',
}

export enum CompanyPhase {
  ACCESS = 'Access',
  EXCHANGE = 'Information Exchange',
  MOMENTUM = 'Momentum',
  LOST = 'Lost',
  KAM = 'Kam',
}

export type ServerResponse<T> = {
  message: string;
  data: T
}

export type Phase = AgentPhase | CompanyPhase;

export type DateRange = MomentRange | undefined;

export type Studies = Study[] | undefined;

export type Surveys = Survey[] | undefined;

export type Imports = Import[] | undefined;

export type Exports = Export[] | undefined;