
const StudiesEmpty = () => {

    return(

        <div style={{
            backgroundColor: 'red',
            margin:'1rem',
            width: "auto",
            height: "auto",
            padding: '1rem',
            }}>
              <p>No existen resultados para mostrar</p>
        </div>
    );
}

export default StudiesEmpty;