import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { selectSlice, setStudy } from '../../store/slices/studies';

const StudySelector: FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const studies = useAppSelector(selectSlice);
  const selectedId = studies.selected?._id;

  function callOnChange (e: React.ChangeEvent<HTMLSelectElement>) {
    e.preventDefault();
    const newId = e.target.value;
    dispatch(setStudy(newId));
  }

  return (
    <select
      className="navbar-select w-select"
      value={selectedId}
      onChange={callOnChange}
    >
      {studies.available.map(({ _id, name }) => (
        <option key={_id} value={_id}>{name}</option>
      ))}
    </select>
  );

}

export default StudySelector;
export { StudySelector };