import { DateRange } from '../../utils';
import { FilterOptions } from '../../store/slices/filters';
import { AnalyticsDateRange, Filter } from '../analytics/analytics.models'

export enum TokenType {
  ADJ = 'ADJ',
  NOUN = 'NOUN',
  PRON = 'PRON',
  ADJNOUN = 'ADJNOUN',
  NOUNADJ = 'NOUNADJ',
}

export interface GetMentionsOptions {
  studyId: string;
  range: DateRange;
  dateRangeField: string;
  typeMentions: TokenType;
  fieldSentiment: string; 
  fieldSyntaxTokens: string; 
  filters?: FilterOptions[];
}

export interface GetMentionsRequest {
  studyId: string;
  dateRange: AnalyticsDateRange;
  typeMentions: TokenType;
  fieldSentiment: string; 
  fieldSyntaxTokens: string;
  filters?: Filter[];
}

export interface MentionData {
  _id: string;
  n: number;
  positive: number;
  neutral: number;
  mixed: number;
  negative: number;
  positivePercentage: number;
  neutralPercentage: number;
  mixedPercentage: number;
  negativePercentage: number;
}

export type MentionsResult = MentionData[];