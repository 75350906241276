import React, { FC } from 'react';
import moment from 'moment';
import { LocalizedText } from '../../services/filters';
import './comment.css';
import { TagEditable } from '../../screens/comments/widgets/tag-editable.w';

const TERMS: any = {
  1: 'Muy insatisfecho',
  2: 'Poco satisfecho',
  3: 'Ni satisfecho ni insatisfecho',
  4: 'Satisfecho',
  5: 'Muy satisfecho',
  POSITIVE: 'Positivo',
  NEUTRAL: 'Neutro',
  MIXED: 'Mixto',
  NEGATIVE: 'Negativo',
  promoter: 'Promotor',
  neutral: 'Neutro',
  detractor: 'Detractor',
}

function _t (value: string | LocalizedText, defaultValue: string = ''): string {
  if (typeof value === 'string') {
    const translation = TERMS[value];
    return translation ?? value;
  }

  return value?.es || defaultValue;
}

export interface CommentProps {
  data: Record<string, string>;
  editable?: boolean;
  suggestions?: Array<any>;
  tagName?: string;
  fields: {
    comment: string;
    author: string;
    email: string;
    tags: Array<string>;
    date: string;
  }
}

const Comment: FC<CommentProps> = ({
  data,
  editable,
  suggestions,
  tagName,
  fields: {
    comment,
    date,
    author,
    email,
    tags,
  }
}) => (
  <div className="comment-card">
    <div className="comment-date">{moment(data[date]).format('DD/MM/YY HH:mm')}</div>
    <p className="comment-author">{data[author]}</p>
    <p className="comment-text">{data[comment]}</p>
    {editable && 
      <TagEditable
        id={data._id} 
        answerId={data.answerId} 
        tag={tagName && suggestions?.find(s => s.id === data[tagName])}
        suggestions={suggestions}
        tagName= {tagName &&  data[tagName]}
        requiresVerification= {data['requiresVerification']}
      />}
    <div className="comment-meta">
      { tags.map(tag => data[tag] ? <div className={`comment-tag ${tag}`}>{_t(data[tag])}</div>: null)}
    </div>
    <div className="comment-contact">
      <span>{data[email]}</span>
    </div>
  </div>
);

export {
  Comment,
}