import React, { FC, useEffect, useState } from 'react';
import { useAppSelector } from '../../../store/hooks';
import { selectSlice } from '../../../store/slices/studies';
import { selectDateRange, selectFilters } from '../../../store/slices/filters';
import { analytics } from '../../../services';
import { DateRange, toNumber, toPercentage } from '../../../utils';
import { Filter, InvitationPerformanceReport } from '../../../services/analytics';
import { ReportConfig } from '../../../services/views';

export type AnalyticsTablecardProps = ReportConfig<
  {
    dateRangeField: string; // Campo para filtro por fecha
  }
>

interface CellProps {
  name: string; //Titulo de la celda
  number: string; //Total a mostrar
  percentage?: string; //Porcentaje del total a mostrar
  backgroundColor?: string; //Color de fondo para la celda
  description: string; //Informacion del dato
}

const Cell: FC<CellProps> = ({
  name,
  number,
  percentage,
  backgroundColor,
  description
}) => {

  return (
    <div style={{
      justifyContent: 'center',
      display: 'flex',
      backgroundColor: backgroundColor ? backgroundColor : '',
      margin: '0px 12px',
      padding: '12px',
      height: '100%',
      flexDirection: 'column'
    }}>
      <div style={{
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row'
    }}>
      <p style={{ marginRight: '8px', fontSize: '12px', color: 'rgb(98, 98, 98)', textAlign: 'left' }}>{name}</p>
      <img style={{width: '8px'}} src="../images/information.png" title={description} />
    </div>
      <p style={{ fontSize: '24px', textAlign: 'left' }}>{number}</p>
      <p style={{ fontSize: '16px', textAlign: 'left' }}>{percentage ?? ''}</p>
    </div>
  );
}


interface PerformanceTableProps {
  performanceReport?: InvitationPerformanceReport,
  options: any
}

const Table: FC<PerformanceTableProps> = ({
  performanceReport,
  options
}) => {
  const data = performanceReport && performanceReport.chart.length > 0 ? performanceReport.chart : [];
  
  if (data.length === 0) return (
    <p style={{ fontSize: '12px', lineHeight: 1.25 }}>No hay informacion de las fechas solicitadas</p>
  );
  
  return (
    <div style={{
      display: 'grid',
      gridTemplateColumns: `repeat(${data.length}, auto)`,
      padding: '10px',
      margin: 'auto',
      width: `${data.length * 220}px`,
    }}>
      {
        options.map((row: any) => {
          return data.map((element: any) => {
            return <div style={{
              backgroundColor: row.mainbackgroundColor ? row.mainbackgroundColor : '',
            }}>
              {row.type === 'Total' ?
              element.type === 'Total' ? (
                <Cell
                  name={row.name}
                  number={toNumber(element[row.number])}
                  percentage={row.percentage ? toPercentage(element[row.percentage]): undefined}
                  description={row.description}
                />) : (<Cell
                  name={row.name2 === 'type' ? element[row.name2] : row.name2 }
                  number={toNumber(element[row.number2])}
                  percentage={toPercentage(element[row.percentage2])}
                  backgroundColor= {row.backgroundColor ? row.backgroundColor : ''}
                  description={row.description2 + element[row.descriptionElement]}
                />
              ) :element[row.number] >= 0 ? (
                <Cell
                  name={row.name}
                  number={toNumber(element[row.number])}
                  percentage={toPercentage(element[row.percentage2])}
                  backgroundColor= {element.type !== 'Total' ? row.backgroundColor : ''}
                  description={row.description + element[row.descriptionElement]}
              />) : null}
            </div>
          })
        })
      }
    </div >
  );
}

const AnalyticsTablecard: FC<AnalyticsTablecardProps> = ({
  query: {
    dateRangeField,
    baseFilters = []
  },
  options,
}) => {

  const studies = useAppSelector(selectSlice);
  const [loading, setLoading] = useState(true);
  const [opts, setOptions] = useState(options);
  const [responsesPerformance, setResponsesPerformance] = useState<analytics.InvitationPerformanceReport | undefined>(undefined);
  const dateRange = useAppSelector(selectDateRange);
  const filters = useAppSelector(selectFilters);
  const hasBaseFilters = baseFilters.length;
  let fullFilters: Filter[] = []

  useEffect(() => {
    async function loadResponsesPerformance() {
      if (!studies.selected) return;
      try {
        setLoading(true);
        if (hasBaseFilters) {
          fullFilters = baseFilters
        }
        if (filters.length) {
          const standarizedFilters = filters.map(f => ({
            field: f.field,
            values: f.values.map(v => v.value),
          }));
          if (hasBaseFilters) {
            const filtersToOverride = standarizedFilters.map(bf => bf.field)
            const newBaseFilters = baseFilters.filter(f => !filtersToOverride.includes(f.field))
            fullFilters = [...newBaseFilters, ...standarizedFilters]
          } else {
            fullFilters = standarizedFilters
          }
        }
        let reportMailingResult = await analytics.getPerformanceReport(
          studies.selected._id,
          dateRange as DateRange,
          dateRangeField,
          fullFilters
        );
        setResponsesPerformance(reportMailingResult);
      } catch (err: any) {
        console.log('Error al obtener datos de performance: ' + err?.message);
      } finally {
        setLoading(false);
      }
    }

    loadResponsesPerformance();
  }, [studies.selected, dateRange, dateRangeField, filters]);

  return (
    <div style={{
      backgroundColor: 'white',
      borderRadius: 16,
      padding: '40px 16px 24px 16px',
      marginBottom: 16,
      minHeight: 200,
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
      border: '1px solid #DFE2E3',
    }}>
      <div style={{
        position: 'absolute',
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: '#0388D1',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        padding: '8px',
      }}>
        <p style={{ fontSize: '12px', lineHeight: 1, color: 'white' }}>{toNumber(responsesPerformance?.n) || 0} {`${' registros'}`}</p>
      </div>
      {loading ? <p>Cargando datos...</p> : (
        <Table
         performanceReport={responsesPerformance} 
         options= {opts}
        />
      )}
    </div>
  );
}

export {
  AnalyticsTablecard,
}