import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { studies } from '../../services';
import { setAvailable, setStudy, selectStudies } from '../../store/slices/studies';
import { setAvailableViews } from '../../store/slices/views';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { CenteredLayout } from '../../common/layouts';
import StudiesEmpty from './widgets/studies-empty.card';
import StudiesCard from './widgets/studies.card';
import { translateError } from '../../utils/handle-error';
import { listViews } from '../../services/views';

const StartScreen = () => {
  
  const dispatch = useAppDispatch();
  const myStudies = useAppSelector(selectStudies);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  function chooseStudy (_id: string, path: string = '/overview') {
    dispatch(setStudy(_id));
    history.push(path);
  }

  useEffect(() => {

    async function loadMyStudies() {
      try {
        const studyList = await studies.getStudies();
        if (!studyList) return;
        
        const availableViews = await listViews();
        dispatch(setAvailable(studyList));
        dispatch(setAvailableViews(availableViews));
        if (studyList.length === 1) {
          const initialScreen = availableViews.find(s => s.viewOptions?.showOnNavbar);
          setTimeout(() => 
            chooseStudy(studyList[0]._id, initialScreen?.viewName),
            300
          )
          
        } else {
          setLoading(false);
        }
      } catch (err) {
        const error = translateError(err);
        alert(error);
      }
    }
    loadMyStudies();
  }, []);

  if (loading) return null;  
  
  return (
    <CenteredLayout
      title="Selecciona un estudio"
      useSimpleNav
    >
      <div className="studies-grid">
        { myStudies.length === 0 && (
          <StudiesEmpty />
        ) }
        { myStudies.length > 0 && myStudies.map(st => (
          <StudiesCard key={st._id} {...st} onClick={chooseStudy} />
        ))} 
      </div>
    </CenteredLayout>
  );
};

export default StartScreen;
