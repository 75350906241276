import React, { Key, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { storage, auth } from './services';
import { constants } from './utils';
import 'react-dates/initialize';
import { useAppSelector } from './store/hooks';
import { selectAvailableViews } from './store/slices/views';
import { ViewType } from './services/views';
import StartScreen from './screens/start';
import AuthScreen from './screens/auth';
import { AnalyticsScreen } from './screens/analytics';
import { CommentsScreen } from './screens/comments';
import { ExportScreen, NewExportScreen } from './screens/exports';
import { ImportsScreen, NewImportScreen } from './screens/imports';
import { DownloadScreen } from './screens/download';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: process.env.REACT_APP_PRIMARY_COLOR ? process.env.REACT_APP_PRIMARY_COLOR : 'red',
    },
  },
});

const ViewComponents: Record<ViewType, React.ComponentType> = {
  [ViewType.ANALYTICS]: AnalyticsScreen,
  [ViewType.COMMENT_EXPLORER]: CommentsScreen,
  [ViewType.EXPORTS]: ExportScreen,
  [ViewType.NEW_EXPORT]: NewExportScreen,
  [ViewType.IMPORTS]: ImportsScreen,
  [ViewType.NEW_IMPORT]: NewImportScreen,
  [ViewType.DOWNLOAD]: DownloadScreen,
}

function App (): JSX.Element | null {
  const history = useHistory();
  const availableViews = useAppSelector(selectAvailableViews);

  async function tryRefresh () {
    try {
      await auth.refreshCognitoSession();
    } catch (err) {
      console.log('No se pudo actualizar sesión.');
    }
  }

  useEffect(() => {

    async function validateAuth () {
      const refreshToken = storage.getData(constants.AUTH_REFRESH_TOKEN_KEY);
      if (refreshToken) {
        try {
          await auth.refreshCognitoSession();
          const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
          
          if (token) {
            history.push('/start');
          } else {
            history.push('/auth/login');
          }
          
        } catch (err) {
          history.push('/auth/login');
        }
      } else {
        history.push('/auth/login');
      }
    }

    let interval: any;
    interval = setInterval(tryRefresh, 10 * 60 * 1000); // 10 minutos
    validateAuth();
    
    return function cleanup () {
      clearInterval(interval);
    }
    
  }, [history]);

  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <Route path="/auth" component={AuthScreen} />
        <Route path="/start" component={StartScreen} />
        {availableViews.map(({ viewName, viewType }) => (
          <Route key={viewName as Key} path={`/${viewName}`} component={ViewComponents[viewType]} />
        ))}
      </Switch>
    </ThemeProvider>
  );
}

export default App;
