import React, { useEffect, useState } from 'react'
import useSegmentedReport, { SegmentedQuery } from '../../../common/hooks/use-segmented-report'
import BaseTable from '../../../common/widgets/table.w'
import { GridCellParams, GridColDef, GridValueGetterParams } from '@mui/x-data-grid'
import { LocalizedText, SupportedLanguages } from '../../../services/filters'
import { ReportConfig } from '../../../services/views'
import { Tooltip } from '@material-ui/core';

export interface ScaleFormatDef {
  max: number,
  className: string,
}

export type SegmentedReportQuestionTableOptions = {
  varsTitle?: LocalizedText,
  breakdownUseDistinctId?: boolean,
  sort?: string
  limit?: number
}

export type SegmentedReportTableProps = ReportConfig<
  SegmentedQuery,
  SegmentedReportQuestionTableOptions
>

const makeGetDisplay = (field: string, language: SupportedLanguages): GridColDef['valueGetter'] =>
  (params: GridValueGetterParams) => params.row[field]?.[language] ?? '-'

const baseColProps: Partial<GridColDef> = {
  minWidth: 144,
  headerAlign: 'right',
  align: 'right',
  type: 'number',
  sortable: true,
}

const SegmentedReportQuestionTable = ({
  title = { default: '', es: '' },
  query,
  options: {
    varsTitle = { es: ' ', default: ' ' },
    breakdownUseDistinctId,
    sort,
    limit
  },
}: SegmentedReportTableProps) => {
  const [loadReport, loading, error, segmentedReport] = useSegmentedReport(query);
  const [tableCols, setTableCols] = useState<GridColDef[]>([]);
  const [data, setData] = useState<Array<any>>([]);
  const preferredLang = SupportedLanguages.es
  const cols: GridColDef[] = [
    {
      field: 'display',
      type: 'string',
      headerName: varsTitle[preferredLang],
      disableColumnMenu: true,
      width: 200,
      valueGetter: makeGetDisplay('display', preferredLang),
      renderCell: ({ value }: GridCellParams) => (
        <Tooltip title={value || ''} >
          <span>{value}</span>
        </Tooltip>
      ),
      sortable: false,
    },
  ]

  useEffect(() => {
    loadReport();
  }, [loadReport]);

  useEffect(() => {
    if (!loading && !error && segmentedReport) {
      const firstGroup = segmentedReport.groups.shift()
      const firstDetail =  firstGroup && firstGroup.detail[0].breakdown[segmentedReport.breakDownDetail[0].distinctId].details;
      if (firstDetail) {
        Object.keys(firstDetail).forEach(key => {
          cols.push({
            ...baseColProps,
            field: firstDetail[key].distinctId,
            headerName: breakdownUseDistinctId ? firstDetail[key].distinctId : firstDetail[key].display.es,
          })
          cols.push({
            ...baseColProps,
            field: `${firstDetail[key].distinctId}_percentage`,
            headerName: breakdownUseDistinctId ? firstDetail[key].distinctId + ' %' : firstDetail[key].display.es + ' %',
          })
        });
        cols.push({
          ...baseColProps,
          field: 'result',
          headerName: 'Total',
        })
        cols.push({
          ...baseColProps,
          field: 'percentage',
          headerName: 'Total %',
        })
      }
      const newData:any = []
      firstGroup && firstGroup.detail.forEach((fullResult, i) => {
        let segments: Record<string, any> = {}
        let { breakdown, ...segmentedResult } = fullResult
        for (const k in breakdown) {
          segments['display'] = {es: k, default: k}
          segments[`result`] =  breakdown[k].result;
          segments[`percentage`] =  breakdown[k].percentage;
          segments[`distinctId`] =  k
          for (const d in breakdown[k].details) {
            const key = breakdown[k].details[d].distinctId;
            segments[key] =  breakdown[k].details[d].n;
            segments[`${key}_percentage`] =  breakdown[k].details[d].percentage;
          }
          newData.push({
            ...segmentedResult,
            ...segments,
          })
        }
      })

      if (sort === 'top'){
        const reportData =  newData.sort(
          (p1: any, p2:any) => (p1.percentage < p2.percentage) ? 1 : (p1.percentage > p2.percentage) ? -1 : 0);
        setData(reportData.slice(0, limit ? limit : 10));
      } else if (sort === 'bottom'){
        const reportData =  newData.sort(
          (p1: any, p2:any) => (p1.percentage > p2.percentage) ? 1 : (p1.percentage < p2.percentage) ? -1 : 0);
        setData(reportData.slice(0, limit ? limit : 10));
      } else {
        setData(newData);
      }
      setTableCols(cols);
    }
  }, [loading, error, segmentedReport]);

  return (
    <div className="span-two-cols">
      <BaseTable
        rowIdKey="distinctId"
        rows={data}
        columns={tableCols}
        loading={loading}
        onReload={loadReport}
        rowHeight={32}
        hideFooter
        autoHeight
        tableTitle={title[preferredLang]}
        downloadFileName={title[preferredLang]}
        localeText={{
          toolbarFilters: '',
          toolbarExport: '',
          toolbarColumns: 'Columnas',
          noRowsLabel: 'No se encontraron registros.',
          toolbarExportCSV: 'Descargar CSV',
          toolbarExportLabel: 'Exportar',
          toolbarFiltersTooltipShow: 'Mostrar filtros',
          toolbarFiltersTooltipHide: 'Ocultar filtros',
        }}
      />
    </div>
  )
}

export {
  SegmentedReportQuestionTable
}