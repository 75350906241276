import React, { FC, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { auth, storage } from '../../../services'
import { TextInput } from '../../../common/components';
import { messages, validate, constants } from '../../../utils';

type LoginFormProps = {

};

const LoginForm: FC<LoginFormProps> = (

) => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const fn: any = {
    email: setEmail,
    password: setPassword,
  };

  function onValueChange (e: React.ChangeEvent<HTMLInputElement>): void {
    e.preventDefault();
    const newValue: string = e.target.value;
    const fieldName: string = e.target.name;
    fn[fieldName](newValue);
  }

  async function handleSubmit (e: React.FormEvent): Promise<void> {
    try {
      e.preventDefault();
      setLoading(true);
      validate('email', email);
      validate('password', password);
      setErrors(null);
      const {
        needsNewPass,
        loginSuccess,
        context: authContext,
        session,
      } = await auth.login(email, password);
      if (needsNewPass) {
        setLoading(false);
        history.push('/auth/new-password', { guideMessage: messages.REQUIRED_PASS, needsNewPass });
      }
      if (loginSuccess) {
        console.log('Session: ', session);
        const token = session?.getIdToken().getJwtToken();
        const refreshToken = session?.getRefreshToken()?.getToken();
        storage.saveData(constants.AUTH_USERNAME_KEY, email);
        storage.saveData(constants.AUTH_USER_TOKEN_KEY, token);
        storage.saveData(constants.AUTH_REFRESH_TOKEN_KEY, refreshToken);
        history.push('/start');
      }
    } catch (err) {
      console.log('Error en datos: ', err?.message)
      setErrors(err?.message);
      setLoading(false);
    }
  }

  return (
    <form
      style={{ width: '100%' }}
      noValidate
      onSubmit={handleSubmit}
    >
      <TextInput
        label="Email"
        fieldName="email"
        autoComplete="username"
        value={email}
        onChange={onValueChange}
      />
      <TextInput
        label="Contraseña"
        fieldName="password"
        type="password"
        autoComplete="current-password"
        value={password}
        onChange={onValueChange}
      />
      {/* <div className="form-link-box">
        <Link to="/auth/recover-password" className="form-link">
          Restablecer contraseña
        </Link>
      </div> */}
      <input
        disabled={loading}
        type="submit"
        value={loading ? 'Procesando...' : 'Iniciar sesión'}
        className="form-submit-button w-button"
      />
      <p>
        {errors}
      </p>
    </form>
  );
}

export default LoginForm;
export {
  LoginForm,
}