import React from 'react'
import { LocalizedText, SupportedLanguages } from '../../services/filters'

interface CardMessageProps {
  message?: LocalizedText,
  lang?: SupportedLanguages,
}

const CardMessage = ({
  lang = SupportedLanguages.es,
  message = { es: 'Cargando...', default: 'Loading...' },
}: CardMessageProps) => (
  <div className="chart-widget-card-responsive">
    {message[lang]}
  </div>
)

export { CardMessage }